import React, { useContext } from "react"
import "./Footer.scss"
import Context from "../../store/context"
import moment from "moment"

export const Footer = props => {
  const { state } = useContext(Context)

  function renderTextBasedOnState() {
    if (!state.sunrise || !state.sunset) {
      return ""
    }

    const now = moment()

    const rise = moment(state.sunrise, "HH:mm:ss A").local()
    const set = moment(state.sunset, "HH:mm:ss A").local()
    const isLight = now.isBetween(rise, set)

    const riseStr = moment(state.sunrise, "HH:mm:ss A").format("LT")
    const setStr = moment(state.sunset, "HH:mm:ss A").format("LT")

    let str = `For me, the sun rose at ${riseStr} and`

    if (isLight) {
      str += ` and will set at ${setStr} today.`
    } else {
      str += ` and set at ${setStr} today.`
    }

    return str
  }

  return (
    <div className="footer">
      <span>{renderTextBasedOnState()}</span>
      <span>
        Thanks to{" "}
        <a
          href={"https://sunrise-sunset.org/api"}
          target="_blank"
          rel="noopener noreferrer"
        >
          sunrise-sunset.org{" "}
        </a>{" "}
        for letting me use their <b>free</b> API.
      </span>
    </div>
  )
}
