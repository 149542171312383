import React, { useContext, useEffect, useState } from "react"
import { Global, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Context from "../../store/context"
import moment from "moment"
import "./layout.scss"
import ThemeToggler from "../../images/lightmodetoggle.png"
import { useTransition, animated, config } from "react-spring"
import hamburger from "../../images/hamburger.png"
import closeButton from "../../images/close.png"
import { Footer } from "../Footer/Footer"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"

const Layout = ({ children, pathname }) => {
  const { state, dispatch } = useContext(Context)

  const lat = 43.6534817
  const lng = -79.3839347

  const [isOpen, setIsOpen] = useState(false)

  const styleToggleButton = {
    fontSize: "3rem",
    padding: 0,
    border: "none",
    background: "none",
    cursor: "pointer",
  }

  const fullscreenMenu = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: "scale(0.80)",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
      position: "absolute",
      maxHeight: "100vh",
      background: "#1E1C26",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    leave: { opacity: 0, transform: "scale(0.80)" },
    config: config.gentle,
  })

  const openButton = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: "scale(0)",
      position: "absolute",
      right: "0",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
      right: "0",
    },
    leave: { opacity: 0, transform: "scale(0)" },
    config: config.stiff,
  })

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const url = `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&date=today&formatted=1`
  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const { results } = data
        const { sunrise, sunset } = results
        const now = moment()

        const rise = moment.utc(sunrise, "HH:mm:ss A").local()
        const set = moment.utc(sunset, "HH:mm:ss A").local()
        const isLight = now.isBetween(rise, set)

        console.log(`Dark Mode should be toggled: ${isLight ? "off" : "on"}`)

        dispatch({
          type: "SET_DARK_MODE",
          payload: { isDark: !isLight, sunrise: rise, sunset: set },
        })
      })
  }, [])

  const theme = useTheme()

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }

          body {
            height: 100vh;
            background-color: ${state.isDark
              ? theme.dark.background
              : theme.light.background};
          }

          #___gatsby {
            height: 100%;
          }

          .project-card {
            background-color: ${theme.dark.font};
          }

          h1,
          h2 {
            color: ${state.isDark ? theme.dark.font : theme.light.font};
            font-family: "Roboto Slab", serif;
            font-size: 36px;
          }

          label {
            color: ${theme.light.font};
            font-family: "Roboto Slab", serif;
          }

          .constant {
            color: ${theme.light.font};
            font-family: "Monserrat", sans-serif;
            font-size: 14px;
          }

          p {
            color: ${state.isDark ? theme.dark.textFont : theme.light.textFont};
            margin-bottom: 1em;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
          }

          span {
            color: ${state.isDark ? theme.dark.textFont : theme.light.textFont};
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
          }

          a {
            font-family: "Montserrat", sans-serif;
            color: black;
          }

          .resume-link {
            color: ${theme.dark.font};
          }

          .portfolio-link {
            color: ${theme.dark.accent};
          }
        `}
      />
      <div
        className={
          isOpen
            ? "parent-layout-container o-hidden"
            : "parent-layout-container o-auto"
        }
      >
        <div
          style={{
            position: "relative",
            zIndex: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className={isOpen ? "theme-toggler hide" : "theme-toggler show"}
            onClick={() => {
              dispatch({
                type: "TOGGLE_DARK_MODE",
              })
            }}
          >
            <img src={ThemeToggler} height={35} alt="toggler" />
          </div>
          {openButton.map(({ item, key, props }) =>
            !item ? (
              <animated.div key={key} style={props}>
                <button style={styleToggleButton} onClick={toggleMenu}>
                  <img src={hamburger} height={35} alt="hamburger-btn" />
                </button>
              </animated.div>
            ) : (
              <animated.div key={key} style={props}>
                <button style={styleToggleButton} onClick={toggleMenu}>
                  <img src={closeButton} height={35} alt="close-btn" />
                </button>
              </animated.div>
            )
          )}
        </div>
        {children}
        <div>
          {fullscreenMenu.map(
            ({ item, key, props }) =>
              item && (
                <animated.div key={key} style={props}>
                  <ul className="menu-list">
                    <li className={pathname === "/" ? "active" : ""}>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li className={pathname === "/portfolio/" ? "active" : ""}>
                      <Link to={"/portfolio/"}>Portfolio</Link>
                    </li>
                    <li>
                      <a
                        rel="noopener noreferrer"
                        href={withPrefix("/JasonLeeResume.pdf")}
                        target="_blank"
                      >
                        Resume
                      </a>
                    </li>
                  </ul>
                </animated.div>
              )
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Layout
